import { Header6, MarginDiv } from '../styles/styles';

const NotFound = () => {

  return (
     <MarginDiv>
      <Header6>Oops, page not found ⛓️‍💥</Header6>
      <p>Sorry, we couldn't find the page you're looking for.</p>
     </MarginDiv>
  );
};

export default NotFound;
