import { Row, Col } from "antd";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  FooterContainer,
  Language,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = () => {
  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
      >
        <SvgIcon src={src} width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={11} md={11} sm={24} xs={24}>
              <Language>{"Contact"}</Language>
              <Large onClick={() => window.location.assign("/")}>{"Write to us 😊"}</Large>
              <Para>
                {`team@happykoda.com`}
              </Para>
            </Col>
            <Col lg={11} md={11} sm={24} xs={24}>
              <Title>{"Quick links"}</Title>
              <Large onClick={() => window.location.assign("/privacy-policy")}>{"Privacy Policy"}</Large>
              <Large onClick={() => window.location.assign("/terms-of-use")}>{"Terms of Use"}</Large>
            </Col>
            {/* <Col lg={6} md={6} sm={12} xs={12}>
              <Title>{"Notion templates"}</Title>
              <Large onClick={() => window.location.assign("/")}>{"Basic"}</Large>
              <Large onClick={() => window.location.assign("/")}>{"Advanced"}</Large>
            </Col> */}
          </Row>
        </Container>
      </FooterSection>
      {/* <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="/img/svg/logo.svg"
                  aria-label="homepage"
                  width="101px"
                  height="64px"
                />
              </LogoContainer>
            </NavLink>
            <FooterContainer>
              <SocialLink
                href="/"
                src="img/svg/linkedin.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra> */}
    </>
  );
};

export default Footer;
