import { useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { AuthContext } from '../providers/AuthProvider';
import { EMPTY_STRING, LOGIN_PATH } from '../common/constants';
import { auth } from '../firebase';
import Container from '../common/Container';
import { Card } from 'antd';
import { Button } from '../common/Button';
import { MiddleBlockSection } from '../components/MiddleBlock/styles';
import { Slide } from 'react-awesome-reveal';
import { StyledInput } from '../common/Input/styles';
import { useSearchParams } from 'react-router-dom';
import NotionDBService from '../services/notionDbService';
import { Alert, CircularProgress, IconButton } from '@mui/material';
import FuncsService from '../services/funcs';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const Dashboard = () => {
  const { signOut } = useContext(AuthContext);
  const [databases, setDatabases] = useState<NotionDatabaseMin[]>([]);
  const [dbCurrentlySyncing, setDbCurrentlySyncing] = useState<string>(EMPTY_STRING);
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(EMPTY_STRING);
  const [updatedDatabase, setUpdatedDatabase] = useState<boolean>(false);
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);

  const [searchParams] = useSearchParams();
  const notionDbId = searchParams.get('id');
  // const code = searchParams.get('code');

  const setUp = () => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (!loggedInUser) {
        signOut();
        setLoggedInUser(null);
      }
      setLoggedInUser(loggedInUser);
    });
  }

  setUp();

  const fetchDatabases = async () => {
    const notionDatabases = await NotionDBService.getAllDatabasesForUser(loggedInUser?.uid);
    if (notionDatabases) {
      setDatabases(notionDatabases);
      return;
    }
    setDatabases([]);
  }

  const handleCheckoutClick = async () => {
    // Get details of notion DB
    // Create NotionDatabase record
    // Create Enrolment
    // const url: string = await EnrolmentService.getCheckoutSessionUrl(ADVANCED_TEMPLATE);
    // window.location.assign(url);
  }

  const getAccessToken = async (codeInURL) => {
    // const res: boolean = await NotionWorkspaceService.getAccessTokenGivenCode(codeInURL);
    // if (res) {
    //   fetchWorkspaces();
    // }
  }

  const syncDatabase = async (idOfEntry: string) => {
    const res = await FuncsService.syncDatabase(loggedInUser?.uid, idOfEntry);
    if (!res) {
      setIsError(true);
      setMessage("Sorry, something went wrong. Please make sure that all your rows have valid company numbers and your columns are named correctly. Email us at team@happykoda.com if it persists.");
      return;
    } else if (res === "TIMEOUT") {
      setIsError(false);
      setMessage("Looks like you have a long list of updates. You can go back to your Notion database as it updates in the background.");
      return;
    }
    // @ts-ignore
    setMessage(res.message);
    // @ts-ignore
    if (res.status === 200) {
      setDbCurrentlySyncing(EMPTY_STRING);
      setUpdatedDatabase(true);
    // @ts-ignore
    } else if (res.status === 400 || res.status === 500) {
      setIsError(true);
    }
  }

  // useEffect(() => {
  //   if (code) {
  //     getAccessToken(code);
  //   }
  // }, [code])

  const handleSyncClick = async (databaseId: string) => {
    setDbCurrentlySyncing(databaseId);
    await syncDatabase(databaseId);
  }

  useEffect(() => {
    if (loggedInUser) {
      fetchDatabases();
      if (notionDbId && !updatedDatabase) {
        handleSyncClick(notionDbId);
      }
    }
  }, [loggedInUser, notionDbId])

  // const query = new URLSearchParams(window.location.search);
  // if (query.get('success')) {
  //   const sessionId = query.get('session_id');
  // }

  return (
    <Container>
      <MiddleBlockSection style={{ marginBottom: "250px" }}>
        <Slide direction="right" triggerOnce>
          {loggedInUser ? (
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" }}>
              {databases?.length > 0 && <p style={{ margin: "50px 0px 20px" }}>Your Notion Databases</p>}
            {databases?.length > 0 && databases.map((db, index) => (
              <Card key={index}>
                <label>Database Id:</label>
                <StyledInput type="text" name="name" placeholder="Notion Secret" value={db?.databaseId} disabled />
                <label>Notion Secret:</label>
                <StyledInput type="text" name="name" placeholder="Database Id" value={db?.notionSecret} disabled />
                {dbCurrentlySyncing === db?.databaseId ? <CircularProgress style={{ marginTop: "10px", fontSize: "14px !important" }} /> : <Button onClick={() => handleSyncClick(db?.databaseId)}>Sync</Button>}
                {message && dbCurrentlySyncing === db?.databaseId &&
                  <Alert sx={{ alignItems: 'flex-start' }} variant="outlined" color={isError ? "error" : "success"} style={{ marginTop: "20px" }}>
                    {message}
                    <IconButton onClick={() => setMessage(EMPTY_STRING)} color={isError ? "error" : "success"} style={{ marginLeft: "30px" }}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Alert>
                }
              </Card>
            ))}
            {databases?.length === 0 && <p style={{ margin: "50px 0px 5px", fontSize: "18px" }}>Send us an email to connect your Notion Database.</p>}
            {databases?.length === 0 && <p style={{ margin: "0px 0px 20px", fontSize: "18px" }}><a style={{ color: "rgb(8, 8, 9)" }} href="mailto:team@happykoda.com">team@happykoda.com</a></p>}
            {/* <p style={{ margin: "50px 0px 20px" }}>Connect a new Notion Database</p>
            <StyledButton onClick={handleConnectToNotion}>Connect</StyledButton>
            <p style={{ marginBottom: "20px" }}>Add a Notion Database to sync with Companies House</p>
            <StyledInput type="text" name="name" placeholder="Notion Secret" value={""} onChange={handleChange} />
            <StyledInput type="text" name="name" placeholder="Database Id" value={""} onChange={handleChange} />
              <StyledButton onClick={handleCheckoutClick}>Go to checkout</StyledButton> */}
            </div>
          ) : (
            <div>
              <p>Please login to view your databases</p>
              <Button onClick={() => window.location.assign(LOGIN_PATH)}>Login</Button>
            </div>
          )}
        </Slide>
      </MiddleBlockSection>
    </Container>
  );
};

export default Dashboard;
