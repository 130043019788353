import { updateDoc, query, where, getDocs, collection, addDoc } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { ACCOUNT_ID, EMPTY_STRING, EQUALS, NOTION_DATABASES, NOTION_WORKSPACES, OWNER_ID } from "../common/constants";

const addNotionDatabase = async (ownerId: string, notionId: string, title: string): Promise<void> => {  
}

const updateNotionDatabase = async (ndb: NotionDatabaseMin): Promise<void> => {
}

const getAllDatabasesForUser = async (userId: string): Promise<NotionDatabaseMin[] | null> => {
  try {
    const accountsSnapshot = await getDocs(
      query(collection(firestoreDb, NOTION_DATABASES), where(ACCOUNT_ID, EQUALS, userId))
    );
    return accountsSnapshot?.docs?.map(doc => doc.data() as NotionDatabaseMin);
  } catch (e) {
    console.error("Error in getting all databases for user: ", e);
  }
  return null;
}

const NotionDBService = {
  getAllDatabasesForUser
}

export default NotionDBService;
