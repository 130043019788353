import styled from "@emotion/styled";
import { CheckCircle as MuiCheckCircle, Launch, MailOutline } from '@mui/icons-material';
import { Card as MuiCard } from '@mui/material';
import { createGlobalStyle } from "styled-components";
import { StyledButton } from "../common/Button/styles";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Motiva Sans Light";
        src: url("/fonts/Motiva-Sans-Light.ttf") format("truetype");
        font-style: normal;
    }

    @font-face {
        font-family: "Motiva Sans Bold";
        src: url("/fonts/Motiva-Sans-Bold.ttf") format("truetype");
        font-style: normal;
    }


    body,
    html,
    a {
        font-family: 'Motiva Sans Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #76dbff 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 35px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #7e7b7c;
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #76dbff;

        :hover {
            color: #76dbff;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;

export const Input = styled.input`
  width: 95%;
  max-width: 364px;
  margin-bottom: 18px;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid rgba(206,206,206,1.00) !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

export const Span = styled.span`
  font-family: ABCGintoNormal-Bold;
  cursor: pointer;
  text-decoration-line: underline;
  font-weight: bold;
`

export const LoginRegisterHeading = styled.h1`
  line-height: 42.6px;
  letter-spacing: -1.5px;
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 25px;
  width: 95%;
  max-width: 364px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

export const Error = styled.p`
  color: rgb(255, 90, 84);
  font-size: 13px;
  line-height: 15.8px;
  width: 100%;
  max-width: 333px;
  text-align: center;
`
export const ButtonStretched = styled.div`
  background-color: #76dbff;
  border: solid 1px #76dbff;
  width: 95%;
  max-width: 364px;
  min-height: 52px;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
  padding: 6px 12px;
  border-radius: 64px;
  -webkit-line-clamp: 2;
  line-height: 23.8px;
  font-size: 18px;
  display: grid;
  margin-top: 16px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #fff;
  font-weight: 500;

  &:hover {
    background-color: #76dbff;
    border: solid 1px #76dbff;
  }
  &:focus {
    border: solid 1px #76dbff;
  }
`

export const WhiteSpinnerSpan = styled.span`
  position: relative;
  top: 3px;
  text-align: center;
`

export const LoginSignUpText = styled.p`
  text-align: center;
  line-height: 18.5px;
  font-weight: normal;
  font-size: 16px;
  font-style: normal;
`

export const GoogleIconImg = styled.img`
  height: 20px;
  min-width: 20px;
  width: 20px;
  left: 5%;
  position: absolute;
`

export const GoogleButton = styled.div`
  background-color: rgb(255, 255, 255);
  outline: none;
  color: rgba(91,92,90,1.00);
  width: 95%;
  max-width: 364px;
  min-height: 52px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding: 6px 12px;
  border-radius: 64px;
  border: 1px solid rgb(191, 191, 191);
  -webkit-line-clamp: 2;
  line-height: 23.8px;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 35px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  position: relative;
`

export const CardsDiv = styled.div`
  margin: 20px auto;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
  padding: 10px;
  max-width: 90%;
  justify-content: center;

  @media screen and (max-width: 700px) {
    grid-template-columns: auto;
  }
`;

export const Card = styled(MuiCard)`
  min-height: 350px;
  padding: 20px;
  width: 100%;
  overflow: inherit;
  min-width: 300px;
`;

export const CheckCircle = styled(MuiCheckCircle)`
  color: #cdb7ff;
  width: 16px;
  height: 16px;
`;

export const Button = styled(StyledButton)`
  margin: 34px auto 25px;
  box-shadow: none;
`;

export const ListItems = styled.p`
  color: rgb(55, 65, 81);
  font-size: 16px;
  text-align: left;
`;

export const LaunchIcon = styled(Launch)`
  color: rgb(55, 65, 81);
  font-size: 13px;
  margin-left: 2px;
  position: absolute;
  right: -20px;
  top: 4px;
`

export const EmailIcon = styled(MailOutline)`
  color: rgb(55, 65, 81);
  font-size: 13px;
  margin-left: 2px;
  position: absolute;
  right: -20px;
  top: 4px;
`

export const Text = styled.div`
  color: #7e7b7c;
  font-size: 16px;
  margin-bottom: 1em;
`;

export const Price = styled.p`
  color: rgb(0, 0, 0);
  font-size: 36px;
  font-weight: 700;
  height: 54px;
  letter-spacing: -0.9px;
  line-height: 54px;
  margin: 0px 3px 0px 0px;
  display: inline-block;
`;

export const ListRow = styled.div`
  display: flex;
  column-gap: .75rem;
  margin: auto;
  width: 90%;
`;

export const MarginDiv = styled.div`
  margin: 140px auto;
  display: block;
  width: 70%;
`;

export const WhiteButton = styled(StyledButton)`
  background-color: rgb(255, 255, 255);
  color: #76dbff;
  border: none;
`;

export const Header6 = styled.h6`
  color: #76dbff;
  margin-bottom: 0px;
`;

export const IconImg = styled.img`
  width: 50px;
  margin-right: 2px;
  margin-left: 6px;
  margin-bottom: 7px;
`

export const TextDark = styled.p`
  color: #18216d;
`
