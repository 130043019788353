import { lazy } from "react";
import { MiddleBlockSection } from "../components/MiddleBlock/styles";
import { Col } from "antd";
import styled from "@emotion/styled";
import { Header6 } from "../styles/styles";

const Container = lazy(() => import("../common/Container"));
const ScrollToTop = lazy(() => import("../common/ScrollToTop"));

const Para = styled.div`
  text-align: left;
  font-size: 16px;
`

const Heading = styled.div`
  text-align: left;
  font-size: 20px;
  margin: 20px 0px 10px;
`

const PrivacyPolicy = () => {
  return (
    <Container>
      <ScrollToTop />
      <MiddleBlockSection>
        <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: "20px" }}>
          <Header6>Terms of Use</Header6>
          <Para>
            Welcome to HappyKoda. These Terms govern your use of our services, including any content, functionality, and features provided through our website
            and platform, collectively referred to as the 'Services.'
            By accessing or using HappyKoda, you agree to be bound by these Terms. If you do not agree, you must not use our Services.
          </Para>

          <Heading>1. Use of Services</Heading>
          <Para>
            To access certain features of HappyKoda, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your account credentials and are liable for any activity conducted under your account.
          </Para>

          <Heading>2. License to Use HappyKoda</Heading>
          <Para>
            Subject to these Terms, HappyKoda grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Services for your personal or business purposes.
          </Para>

          <Heading>3. User Responsibilities</Heading>
          <Para>
            You agree not to submit content that: Infringes on any intellectual property rights of others; Is unlawful, abusive, or harmful to others; Contains viruses, malware, or other malicious code.
            HappyKoda reserves the right to review and remove any User Content that violates these Terms or is otherwise deemed inappropriate at our sole discretion. Failure to comply with these Terms may result in the suspension or termination of your account.
          </Para>

          <Heading>4. Refund Policy</Heading>
          <Para>
            Some features of HappyKoda may require payment of fees. You agree to pay all applicable fees for your use of the Services. Subscriptions will automatically renew unless canceled. Refunds on monthly plans are provided for customers who have not received the services they have paid for, at our discretion. Refunds for annual subscriptions are provided within 30 days of the subscription renewal date. Please contact team@happykoda.com to begin the refund process.
          </Para>

          <Heading>5. Intellectual Property</Heading>
          <Para>
            All content provided through HappyKoda is the property of HappyKoda or its licensors. You grant us a license to use any feedback you provide.
          </Para>

          <Heading>6. Privacy</Heading>
          <Para>
            Please review our Privacy Policy to understand how we collect, use, and protect your personal information.
          </Para>

          <Heading>7. Limitation of Liability</Heading>
          <Para>
            To the fullest extent permitted by law, HappyKoda shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of the Services.
          </Para>

          <Heading>8. Indemnification</Heading>
          <Para>
            You agree to indemnify and hold harmless HappyKoda from any claims arising out of your use of the Services or violation of these Terms.
          </Para>

          <Heading>9. Changes to These Terms</Heading>
          <Para>
            We may modify these Terms at any time. Your continued use of the Services after such changes constitutes your acceptance of the new Terms.
          </Para>

          <Heading>10. Contact Information</Heading>
          <Para>
            If you have any questions or concerns about these Terms, please contact us at team@happykoda.com.
          </Para>

        </Col>
    </MiddleBlockSection>
    </Container>
  );
};

export default PrivacyPolicy;
