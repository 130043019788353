'use client';
import { useContext, useState } from 'react';
import { CardContent } from '@mui/material';
import { Slide } from 'react-awesome-reveal';
import { onAuthStateChanged, User } from 'firebase/auth';
import { Col, Row } from 'antd';

import { ContentWrapper, MiddleBlockSection } from '../components/MiddleBlock/styles';
import { Button, Card, CardsDiv, CheckCircle, ListItems, ListRow, Price, Text, TextDark } from '../styles/styles';
import { AuthContext } from '../providers/AuthProvider';
import { ADVANCED_TEMPLATE, BASIC_TEMPLATE, CUSTOM_TEMPLATE, EMPTY_STRING, LOGIN_PATH, SELECT_SYNC_PATH } from '../common/constants';
import Container from '../common/Container';
import EnrolmentService from '../services/enrolmentService';
import { auth } from '../firebase';

const PricingOnboarding = () => {
  const { signOut } = useContext(AuthContext);
  const [userId, setUserId] = useState<string>(EMPTY_STRING);

  const setUp = () => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (!loggedInUser) {
        signOut();
        window.location.assign(LOGIN_PATH);
        return;
      }
      setUserId(loggedInUser.uid);
    });
  }

  setUp();

  const handleSelectClick = async (productType: string) => {
    if (userId) {
      await EnrolmentService.addEnrolment(userId, productType);
      if (productType === BASIC_TEMPLATE) {
        window.location.assign("LINK TO BASIC ON NOTION");
        return;
      }
      const url: string = await EnrolmentService.getCheckoutSessionUrl(ADVANCED_TEMPLATE);
      window.location.assign(url);
    }
  }

  const handleCustomClick = async () => {
    if (userId) {
      await EnrolmentService.addEnrolment(userId, CUSTOM_TEMPLATE);
    }
  }

  return (
    <Container>
      <MiddleBlockSection style={{ marginBottom: "250px", marginTop: "50px" }}>
        <Slide direction="right" triggerOnce>
          <Row justify="center" align="middle">
            <ContentWrapper>
              <Col lg={24} md={24} sm={24} xs={24}>
                <TextDark style={{ marginBottom: "40px" }}>Which package would you like?</TextDark>
                <CardsDiv>
                  <Card>
                    <CardContent>
                      <TextDark>Basic</TextDark>
                      <Text><Price>£0</Price></Text>
                      <Button style={{ marginTop: "30px" }} onClick={() => window.open("https://happykodaaccounting.notion.site/Free-HappyKoda-s-Accounting-Practice-167f0813f3b180a98b2cf5f679bd11ba")}>Select</Button>
                      <ListItems style={{ textAlign: "center" }}>Includes:</ListItems>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Team task assignment</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Task tracking</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Client onboarding</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Client data sheet</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Communications tracker</ListItems>
                      </ListRow>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent>
                      <TextDark>Advanced</TextDark>
                      <Text><Price>£29</Price> one-time fee</Text>
                      <Button style={{ marginTop: "30px" }} onClick={() => window.open("https://buy.stripe.com/9AQ6pef0r4ml4IE289")}>Select</Button>
                      <ListItems style={{ textAlign: "center" }}>Includes:</ListItems>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Team task assignment</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Detailed task tracking</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Detailed client onboarding</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Detailed client data sheet</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Task Automation</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Monthly reminders</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Detailed template processes</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Video tutorials</ListItems>
                      </ListRow>
                    </CardContent>
                  </Card>
                  <Card>
                    <CardContent>
                      <TextDark>Advanced + Sync</TextDark>
                      <Text style={{ marginBottom: "0px" }}><Price>£29</Price> one-time fee</Text>
                      <Text style={{ marginBottom: "0px" }}>Plus <span style={{ color: "rgb(0, 0, 0)", fontWeight: "700" }}>£4.99</span> / month</Text>
                      <Button style={{ marginTop: "20px" }} onClick={() => window.open("https://buy.stripe.com/eVafZOg4vdWVdfa5kk")}>Select</Button>
                      <ListItems style={{ textAlign: "center" }}>Advanced plus:</ListItems>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>Automatic updates from Companies House</ListItems>
                      </ListRow>
                      <ListRow>
                        <CheckCircle />
                        <ListItems>24/7 support</ListItems>
                      </ListRow>
                    </CardContent>
                  </Card>
                </CardsDiv>
              </Col>
            </ContentWrapper>
          </Row>
        </Slide>
      </MiddleBlockSection>
    </Container>
  );
};

export default PricingOnboarding;
