import { lazy } from "react";
import { MiddleBlockSection } from "../components/MiddleBlock/styles";
import { Col } from "antd";
import styled from "@emotion/styled";
import { Header6 } from "../styles/styles";

const Container = lazy(() => import("../common/Container"));
const ScrollToTop = lazy(() => import("../common/ScrollToTop"));

const Para = styled.div`
  text-align: left;
  font-size: 16px;
`

const Heading = styled.div`
  text-align: left;
  font-size: 20px;
  margin: 20px 0px 10px;
`

const PrivacyPolicy = () => {
  return (
    <Container>
      <ScrollToTop />
      <MiddleBlockSection>
        <Col lg={24} md={24} sm={24} xs={24} style={{ marginBottom: "20px" }}>
          <Header6>Privacy Policy</Header6>
          <Heading>1. Information We Collect</Heading>
          <Para>
            We collect information that you provide directly to us. This includes account information.
          </Para>

          <Heading>2. How We Use Your Information</Heading>
          <Para>
            We use the collected information to provide, maintain, and improve HappyKoda's services, personalize your experience, offer customer support, and comply with legal obligations.
          </Para>

          <Heading>3. How We Share Your Information</Heading>
          <Para>
            We do not sell or rent your personal information. We do not share it with anyone.
          </Para>

          <Heading>4. Data Security</Heading>
          <Para>
            We employ industry-standard security measures to protect your data, including encryption and access controls.
          </Para>

          <Heading>5. Your Rights and Choices</Heading>
          <Para>
            You may have rights to access, correct, or delete your personal information. You can also opt-out of marketing communications. Contact us at team@happykoda.com to exercise these rights or for any privacy-related inquiries.
          </Para>

          <Heading>6. Changes to This Privacy Policy</Heading>
          <Para>
            We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify you of any material changes. Please review this policy regularly to stay informed about how we protect your information.
          </Para>

          <Heading>7. Contact Us</Heading>
          <Para>
            If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at team@happykoda.com. We're committed to addressing your privacy concerns promptly and thoroughly.
          </Para>
        </Col>
    </MiddleBlockSection>
    </Container>
  );
};

export default PrivacyPolicy;
