import { httpsCallable } from "firebase/functions";

import { functions } from "../firebase";

const getAccessTokenGivenCode = async (code: string) : Promise<boolean> => {
  const getNotionAccessToken = httpsCallable(functions, 'notionFuncs.getNotionAccessToken');
  const res = await getNotionAccessToken({ code });
  const data = res.data;
  // @ts-ignore
  const sanitizedMessage = data.text;
  console.log(data);
  console.log(sanitizedMessage);
  return false;
}

const syncDatabase = async (accountId: string, databaseId: string) => {
  const kodaUpdate = httpsCallable(functions, 'koda-update', {
    timeout: 10000 // 10 seconds timeout
  });
  try {
    const result = await kodaUpdate({ userId: accountId, databaseId });
    return result.data;
  } catch (error: any) {
    if (error.code === 'functions/cancelled' || error.code === 'functions/deadline-exceeded') {
      console.error('Request timed out:', error);
      return "TIMEOUT";
    }
    console.error('Error syncing database:', {
      message: error.message,
      code: error.code,
      details: error
    });
  }
  return null;
}

const FuncsService = {
  getAccessTokenGivenCode,
  syncDatabase
}

export default FuncsService;
