import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
import "antd/dist/antd.min.css";

import Router from "./router";
import AuthProvider from "./providers/AuthProvider";
import './styles/responsive.css';

const App = () => (
  <AuthProvider>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </AuthProvider>
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
