'use client';
import { CardContent } from '@mui/material';
import { Slide } from 'react-awesome-reveal';
import { ContentWrapper, MiddleBlockSection } from './MiddleBlock/styles';
import { Col, Row } from 'antd';

import { Button, Card, CardsDiv, CheckCircle, Header6, ListItems, ListRow, Price, Text, TextDark } from '../styles/styles';

const Pricing = ({id}) => {

  return (
    <MiddleBlockSection id={id} className='padding-mobile'>
      <Slide direction="right" triggerOnce>
        <Row justify="center" align="middle">
          <ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <Header6>Pricing</Header6>
              <Text style={{ marginTop: "10px" }}>Worried about onboarding? We will be with you all the way!</Text>
              <Text>Get in touch with us (<a href="mailto:team@happykoda.com">team@happykoda.com</a>) if you have any questions.</Text>
              <CardsDiv>
                <Card>
                  <CardContent>
                    <TextDark>Basic</TextDark>
                    <Text><Price>£0</Price></Text>
                    <Button style={{ marginTop: "30px" }} onClick={() => window.open("https://happykodaaccounting.notion.site/Free-HappyKoda-s-Accounting-Practice-167f0813f3b180a98b2cf5f679bd11ba")}>Select</Button>
                    <ListItems style={{ textAlign: "center" }}>Includes:</ListItems>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Team task assignment</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Task tracking</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Client onboarding</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Client data sheet</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Communications tracker</ListItems>
                    </ListRow>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <TextDark>Advanced</TextDark>
                    <Text><Price>£29</Price> one-time fee</Text>
                    <Button style={{ marginTop: "30px" }} onClick={() => window.open("https://buy.stripe.com/9AQ6pef0r4ml4IE289")}>Select</Button>
                    <ListItems style={{ textAlign: "center" }}>Includes:</ListItems>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Team task assignment</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Detailed task tracking</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Detailed client onboarding</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Detailed client data sheet</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Task Automation</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Monthly reminders</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Detailed template processes</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Video tutorials</ListItems>
                    </ListRow>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <TextDark>Advanced + Sync</TextDark>
                    <Text style={{ marginBottom: "0px" }}><Price>£29</Price> one-time fee</Text>
                    <Text style={{ marginBottom: "0px" }}>Plus <span style={{ color: "rgb(0, 0, 0)", fontWeight: "700" }}>£4.99</span> / month</Text>
                    <Button style={{ marginTop: "20px" }} onClick={() => window.open("https://buy.stripe.com/eVafZOg4vdWVdfa5kk")}>Select</Button>
                    <ListItems style={{ textAlign: "center" }}>Advanced plus:</ListItems>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Automatic updates from Companies House</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>24/7 support</ListItems>
                    </ListRow>
                  </CardContent>
                </Card>
              </CardsDiv>
            </Col>
          </ContentWrapper>
        </Row>
      </Slide>
    </MiddleBlockSection>
  );
}

export default Pricing;
