import { useState, ChangeEvent, useContext, useEffect, KeyboardEvent } from 'react';
import styled from '@emotion/styled';
import { UserCredential, User, createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { CircularProgress } from '@mui/material';

import { auth, googleAuthProvider } from '../firebase';
import { AuthContext } from '../providers/AuthProvider';
import { ButtonStretched, Error, GoogleButton, GoogleIconImg, LoginRegisterHeading, LoginSignUpText, WhiteSpinnerSpan } from '../styles/styles';
import { DASHBOARD_PATH, EMPTY_STRING, ENTER, HOME_PATH, LOGIN_PATH, SELECT_PLAN_PATH } from '../common/constants';
import GoogleIcon from "../images/google.svg";
import { emptyAccount } from '../services/emptyObjs';
import UserService from '../services/userService';
import Container from '../common/Container';

const Input = styled.input`
  width: 95%;
  max-width: 364px;
  margin-bottom: 18px;
  padding: 10px;
  border-radius: 16px;
  border: 1px solid rgba(206,206,206,1.00) !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

const Span = styled.span`
  font-family: ABCGintoNormal-Bold;
  cursor: pointer;
  text-decoration-line: underline;
  font-weight: bold;
`

const getEmailStorage = () => {
  if (localStorage.getItem("email")) {
    const storageEmail = localStorage.getItem("email");
    localStorage.removeItem("email");
    return storageEmail;
  }
  return EMPTY_STRING;
}

 const Register = () => {
  const [email, setEmail] = useState(getEmailStorage());
  const [password, setPassword] = useState(EMPTY_STRING);
  const [confirmPassword, setConfirmPassword] = useState(EMPTY_STRING);
  const [registerError, setRegisterError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
  const [googleIsLoading, setGoogleIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { currentUser, setCurrentUserToStorage } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      window.location.assign(DASHBOARD_PATH);
    };
  }, []);

  const existingUser = async (authUser: User) : Promise<boolean> => {
    if (authUser && setCurrentUserToStorage) {
      const account: Account | null = await UserService.getAccountById(authUser.uid);
      if (account) {
        setCurrentUserToStorage({ ...account });
        return true;
      }
    }
    return false;
  }

  const handleSignInGoogleClick = async () => {
    setGoogleIsLoading(true);
    try {
      const userCredential: UserCredential = await signInWithPopup(auth, googleAuthProvider);
      if (!userCredential) {
        return;
      }
      const authUser: User = userCredential.user;
      const userExists = await existingUser(authUser);
      if (!userExists) {
        const newAccount: Account = {
          ...emptyAccount,
          id: userCredential.user.uid,
          email: authUser?.email,
          createdAt: Date.now(),
          updatedAt: Date.now()
        };
        await UserService.addAccount(newAccount);
        setCurrentUserToStorage(newAccount);
        window.location.assign(SELECT_PLAN_PATH);
        return;
      }
      window.location.assign(DASHBOARD_PATH);
    } catch (error) {
      setRegisterError(true);
      setErrorMessage("Cannot log you in with that account. Please try with another.");
    }
    setGoogleIsLoading(false);
  };

  const saveUserAndRedirect = async () => {
    setLoading(true);
    try {
      const userCredential: UserCredential = await createUserWithEmailAndPassword(auth, email, password);
      if (!userCredential) {
        setRegisterError(true);
        setErrorMessage("Sorry, there was some technical issue. Please try again later or contact us if you need help.");
        setLoading(false);
        return;
      };
      const authUser = userCredential.user;
      if (authUser && setCurrentUserToStorage) {
        const newAccount: Account = {
          ...emptyAccount,
          id: authUser.uid,
          email: authUser.email,
          createdAt: Date.now(),
          updatedAt: Date.now()
        };
        await UserService.addAccount(newAccount);
        setCurrentUserToStorage(newAccount);
        window.location.assign(SELECT_PLAN_PATH);
      }
    } catch (error) {
      setRegisterError(true);
      setErrorMessage("Sorry, we cannot register you with that email and password. Please contact us if you need help.");
    }
    setLoading(false);
  }

  const handleSignUpClick = async () => {
    if (email === EMPTY_STRING || password === EMPTY_STRING || confirmPassword === EMPTY_STRING) {
      setRegisterError(true);
      setErrorMessage("Please enter your email and password.");
      return;
    } else if (password !== confirmPassword) {
      setRegisterError(true);
      setErrorMessage("Passwords don't match.");
      return;
    } else if (password?.length < 6) {
      setRegisterError(true);
      setErrorMessage("Use 8 characters or more for your password.");
      return;
    }
    await saveUserAndRedirect();
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterError(false);
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterError(false);
    setPassword(event.target.value);
  }

  const handleConfirmPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisterError(false);
    setConfirmPassword(event.target.value);
  }

  const handleSignInClick = () => {
    setRegisterError(false);
    window.location.assign(LOGIN_PATH);
    window.scrollTo(0, 0);
  }

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if(event.key === ENTER) {
      handleSignUpClick();
    }
  };

  return (
    <Container>
      <div style={{ marginBottom: "250px", marginTop: "50px" }}>
        <LoginRegisterHeading>Register</LoginRegisterHeading>
        <form>
          {!googleIsLoading && <>
            <Input type='email' placeholder='Email' value={email} onChange={handleEmailChange} required />
            {registerError && <Error style={{ marginRight: "auto", marginLeft: "auto" }}>{errorMessage}</Error>}
            <Input type='password' placeholder='Password' value={password} onChange={handlePasswordChange} required />
            <Input type='password' placeholder='Confirm password' value={confirmPassword} onChange={handleConfirmPasswordChange} onKeyDown={handleEnterPress} required />
            <ButtonStretched onClick={handleSignUpClick} style={{ marginBottom: "20px" }}>
              {loading &&
                <WhiteSpinnerSpan>
                  <CircularProgress style={{ width: "30px", height: "30px", color: "#fff" }} />
                </WhiteSpinnerSpan>
              }
              {!loading && <>Sign Up</>}
            </ButtonStretched>
            <LoginSignUpText style={{ fontSize: "15px" }}>or</LoginSignUpText></>
          }
          <GoogleButton onClick={handleSignInGoogleClick}>
            <GoogleIconImg src={GoogleIcon} />
            {googleIsLoading ?
              <span style={{ width: "180px", textAlign: "center" }}>
                <CircularProgress style={{ width: "30px", height: "30px" }} />
              </span>
              :
              <>Continue with Google</>
            }
          </GoogleButton>
        </form>
        <LoginSignUpText>Already have an account? <Span onClick={handleSignInClick}>Sign in</Span></LoginSignUpText>
      </div>
    </Container>
  );
};

export default Register;
