import { doc, updateDoc, getDoc, setDoc, query, where, getDocs, collection, deleteDoc } from "firebase/firestore";

import { firestoreDb } from "../firebase";
import { ACCOUNTS, EMAIL, EMPTY_STRING, EQUALS, USERNAME } from "../common/constants";

const addAccount = async (account: Account): Promise<void> => {
  try {
    const accountsSnapshot = await getDocs(query(collection(firestoreDb, ACCOUNTS), where(EMAIL, EQUALS, account.email)));
    if (accountsSnapshot.empty) {
      await setDoc(doc(firestoreDb, ACCOUNTS, account.id), { ...account });
    }
  } catch (e) {
    console.error("Error creating new account: ", e);
  }
}

const getAllAccounts = async () : Promise<Account[] | null> => {
  try {
    const accountsSnapshot = await getDocs(collection(firestoreDb, ACCOUNTS));
    return accountsSnapshot?.docs?.map(doc => doc.data() as Account);
  } catch (e) {
    console.error("Error in getting all accounts: ", e);
  }
  return null;
}

const getAccountById = async (userId: string) : Promise<Account | null> => {
  try {
    const account = await getDoc(doc(firestoreDb, ACCOUNTS, userId));
    if (account.exists()) {
      return account.data() as Account;
    }
    return null;
  } catch (e) {
    console.error("Error getting account by id: ", e);
  }
  return null;
}

const updateAccount = async (accountObj: Account) : Promise<void> => {
  try {
    const accountRef = doc(firestoreDb, ACCOUNTS, accountObj.id);
    const account = await getDoc(accountRef);
    if (account.exists()) {
      await updateDoc(accountRef, { ...accountObj });
    } else {
      await setDoc(accountRef, { ...accountObj });
    }
  } catch (e) {
    console.error("Error creating or updating user: ", e);
  }
}

const getAccountByUsername = async (username: string) : Promise<Account | null> => {
  try {
    const accountsRef = collection(firestoreDb, ACCOUNTS);
    const accountsSnapshot = await getDocs(
      query(accountsRef, where(USERNAME, EQUALS, username))
    );
    const accounts = accountsSnapshot.docs;
    if (accounts?.length === 1) {
      return accounts[0].data() as Account;
    }
    return null;
  } catch (e) {
    console.error("Error getting user: ", e);
  }
  return null;
}

const deleteUser = async (accountId: string) : Promise<void> => {
  try {
    await deleteDoc(doc(firestoreDb, ACCOUNTS, accountId));
  } catch (e) {
    console.error("Error deleting user by id: ", e);
  }
}

const containsSpecialChars = (str: string) : boolean => {
  const specialChars = /[`!@#$%^&*()+\-=\[\]{};'’:"\\|,<>\/?~\s/]/;
  return specialChars.test(str);
}

const getNameOrUsername = (name: string, username: string) : string => {
  if (name && name.trim() !== EMPTY_STRING) {
    const firstname = name?.split(" ")[0];
    return firstname.charAt(0).toUpperCase() + firstname.slice(1);
  }
  return `@${username}`;
}

const capitalizeFullname = (fullname: string) : string => {
  const names = fullname.split(" ");
  if (names?.length > 1) {
    return names[0].charAt(0).toUpperCase() + names[0].slice(1) + " " + names[1].charAt(0).toUpperCase() + names[1].slice(1);
  } else if (names?.length === 1) {
    return names[0].charAt(0).toUpperCase() + names[0].slice(1);
  }
  return EMPTY_STRING;
}

const UserService = {
  addAccount,
  getAllAccounts,
  getAccountById,
  getAccountByUsername,
  getNameOrUsername,
  updateAccount,
  containsSpecialChars,
  capitalizeFullname,
  deleteUser
}

export default UserService;
