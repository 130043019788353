import { useContext, useState } from "react";
import { Row, Col, Drawer } from "antd";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { DASHBOARD_PATH, HOME_PATH, LOGIN_PATH, REGISTER_PATH, SELECT_PLAN_PATH, SELECT_SYNC_PATH } from "../../common/constants";
import { AuthContext } from "../../providers/AuthProvider";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "../../firebase";
import { useLocation } from "react-router-dom";
import { WhiteButton } from "../../styles/styles";

const Header = () => {
  const location = useLocation();
  const { signOut } = useContext(AuthContext);
  const [visible, setVisibility] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);

  const setUp = () => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (!loggedInUser) {
        signOut();
        setLoggedInUser(null);
        return;
      }
      setLoggedInUser(loggedInUser);
    });
  }

  setUp();

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const handleLogout = () => {
    signOut();
    window.location.assign(HOME_PATH);
  }

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element?.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        {location.pathname === HOME_PATH && <>
          <CustomNavLinkSmall onClick={() => scrollTo("features")}>
            <Span>{"About"}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={() => scrollTo("pricing")}>
            <Span>{"Pricing"}</Span>
          </CustomNavLinkSmall>
        </>}

        { loggedInUser && (location.pathname === DASHBOARD_PATH || location.pathname === SELECT_SYNC_PATH || location.pathname === SELECT_PLAN_PATH) && <>
          <CustomNavLinkSmall onClick={() => window.location.assign(DASHBOARD_PATH)}>
            <Span>{"Dashboard"}</Span>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={() => window.location.assign(SELECT_PLAN_PATH)}>
            <Span>{"Upgrade"}</Span>
          </CustomNavLinkSmall>
        </>}

        <CustomNavLinkSmall style={{ width: "180px" }}>
          <Span>
            { (!loggedInUser && (location.pathname === HOME_PATH || location.pathname === REGISTER_PATH || location.pathname === "/privacy-policy" || location.pathname === "/terms-of-use")) &&
              <WhiteButton onClick={() => window.location.assign(LOGIN_PATH)}>{"Log in"}</WhiteButton>
            }
            { !loggedInUser && location.pathname === LOGIN_PATH && <WhiteButton onClick={() => window.location.assign(REGISTER_PATH)}>{"Register"}</WhiteButton> }
            { !loggedInUser && location.pathname === DASHBOARD_PATH && <WhiteButton onClick={() => window.location.assign(REGISTER_PATH)}>{"Login"}</WhiteButton> }
            { loggedInUser &&
              <WhiteButton onClick={handleLogout}>{"Log out"}</WhiteButton>
            }
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="https://firebasestorage.googleapis.com/v0/b/sunrise-e474d.appspot.com/o/koda%2Flogo.png?alt=media&token=206c694c-d617-405d-a8b5-fdb7541d22f6" width="240px" height="50px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default Header;
